import { graphql } from 'gatsby';
import React from 'react';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacySettingsPage = () => <Layout disableIntercomChat />;

export const Head = ({
  data: {
    privacySettings: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
    {/* Note: Use a standard <script> tag for this so it's not affected by hydration */}
    <script>
      {`function experienceClosed(reason) {
        if (reason !== 'willNotShow') {
          const filteredLocations =
            window.locations?.filter?.(
              (location) => !location.includes('/privacy-settings')
            ) || [];

          if (filteredLocations.length && filteredLocations[filteredLocations.length - 1]) {
            window.location.assign(filteredLocations[filteredLocations.length - 1]);
          } else {
            window.location.href = '/';
          }
        }
      }

      if (typeof window.ketch === 'function') {
        window.ketch('showPreferences', { tab: 'overviewTab' });
        window.ketch('onHideExperience', experienceClosed);
      } else {
        window.semaphore = window.semaphore || [];
        window.semaphore.push(['showPreferences', { tab: 'overviewTab' }]);
        window.semaphore.push(['onHideExperience', experienceClosed]);
      }`}
    </script>
  </GeneralHead>
);

export const query = graphql`
  query PrivacySettingsQuery {
    privacySettings: datoCmsKetchPrivacySettingsPage {
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default PrivacySettingsPage;
